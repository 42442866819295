@import "variables";

body{
  font-family: "Nunito", sans-serif; 
  background-color: $bgColor;
  color: #595959
}


@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap");

:root {
  --bg-color: #f4f4f4;
  --primary-color: #3498db;
  --secondary-color: #fb0;
  --white-color: #fff;
}

body {
  font-family: "Nunito", sans-serif;
  background-color: var(--bg-color);
  color: #595959;
}
.container {
  max-width: 800px;
}
.bg-primary {
  background-color: var(--primary-color) !important;
}
.btn-primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
.btn-primary:hover {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}
.text-primary,
a {
  color: var(--primary-color) !important;
}
a {
  text-decoration: none;
}

.form-control,
.form-select {
  border-radius: 0;
  border: 0;
  border-bottom: 2px solid #ccc;
  padding: 10px 0;
}
.form-control:focus,
.form-select:focus {
  box-shadow: none;
}

h1 {
  font-size: 18px;
  font-weight: bold;
}
header {
  background-color: inherit;
  z-index: 999;
}
.notification {
  position: relative;
}
.notification .badge {
  position: absolute;
  width: 20px;
  height: 20px;
  font-size: 10px;
  border-radius: 50%;
  background-color: var(--primary-color);
  color: var(--white-color);
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  top: -2px;
  right: -5px;
}
header button {
  border: 0;
  background-color: transparent;
  /* color: var(--white-color); */
  padding: 0;
  font-size: 48px;
}
header select {
  background-color: transparent;
  border: 0;
  outline: 0 !important;
  cursor: pointer;
  max-width: 90px;
}
/* Styles for the offcanvas menu */
.offcanvas-body ul {
  list-style: none;
  padding-left: 0;
}

.offcanvas-body li a {
  border-bottom: 1px solid;
  display: block;
  text-decoration: none;
  font-size: 18px;
  transition: color 0.2s ease-in-out;
  padding: 10px 0;
  color: var(--white-color) !important;
}
.offcanvas-body li a i {
  display: inline-block;
  width: 30px;
}
.offcanvas-body li:last-child a {
  border-bottom: 0;
}

header .location-icon {
  font-size: 24px;
}
header .navbar-icon,
header .notification {
  display: flex;
  background-color: var(--white-color);
  font-size: 30px;
  width: 50px;
  height: 50px;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  color: var(--primary-color);
}
header .navbar {
  color: var(--white-color) !important;
  font-size: 30px;
}
header .notification {
  border-radius: 50%;
}

.search-bar {
  background-color: var(--white-color);
  border-radius: 15px;
}
.search-bar > * {
  background-color: transparent !important;
  border: 0;
}
.search-bar .form-control {
  height: 50px;
  outline: none !important;
  box-shadow: none !important;
  padding-left: 10px;
  padding-right: 10px;
}

.category-block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  padding: 10px 5px;
  border-radius: 18px;
}
.category-block .icon {
  font-size: 40px;
  /* color: var(--primary-color); */
}
.category-block .label {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* color: var(--primary-color); */
}

.business-block {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-size: 12px;
  position: relative;
}
.business-block .image {
  position: relative;
}
.business-block .image .label-text {
  position: absolute;
  top: 10px;
  left: -2px;
  padding: 3px 10px;
  background-color: #fb0;
  font-weight: bold;
  border-radius: 0 3px 3px 0;
}
.business-block .image img {
  width: 100%;
  height: 100px;
  object-fit: cover;
}
.business-block .fav {
  position: absolute;
  background-color: transparent;
  padding: 0;
  border: 0;
  top: 5px;
  right: 5px;
  font-size: 20px;
  color: red;
}
.business-block .details h4 {
  font-size: 14px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.business-block .details .category {
  color: var(--primary-color);
}
.business-block .details .rating {
  color: #fb0;
}

.event-owl-carousel .owl-stage {
  display: flex;
  align-items: center;
}

.event-owl-carousel .owl-item img {
  height: 180px;
}
.event-owl-carousel .owl-item.active.center img {
  height: 200px;
}

.delete {
  color: #808080;
}
.price {
  font-weight: bold;
}

footer {
  position: fixed;
  bottom: 10px;
  left: 0px;
  right: 0px;
  z-index: 999;
  /* background-color: var(--primary-color); */
  color: var(--white-color);
  text-align: center;
  font-size: 20px;
  height: 55px;
}
footer .footer-text {
  font-size: 10px;
}
footer .add-menu {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--white-color);
  color: var(--primary-color);
  width: 60px;
  height: 60px;
  font-size: 26px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 5px;
  border: 1px solid #ccc;
}
footer .add-menu > a {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
footer .add-menu .inner-circle {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid var(--primary-color);
}
.rounded-circle {
  border-radius: 50%!important;
  width: 100px;
}


.offcanvas-body li a {
    border-bottom: 1px solid;
    display: block;
    text-decoration: none;
    font-size: 18px;
    font-weight: bold;
    transition: color 0.2s ease-in-out;
    padding: 10px 0;
    color: var(--white-color) !important;
}
/* Custom Multiple Selection */
.select-container .select-block {
  border: 3px solid #ccc;
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 15px;
}
.select-container input[type="checkbox"]:checked + .select-block {
  border-color: var(--primary-color);
}

.profile-tab {
  background-color: var(--bs-light);
  border-radius: 0px;
  overflow: hidden;
}
.profile-tab li.nav-item .nav-link {
  border-radius: 0;
}
.profile-tab li.nav-item .nav-link {
  color: inherit;
  text-transform: uppercase;
  font-size: 12px;
}
.profile-tab li.nav-item .nav-link.active {
  background-color: transparent;
  color: var(--primary-color);
  border-bottom: 3px solid var(--primary-color);
}

.product .card-body {
  padding: 5px;
}
.product h6 {
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: var(--primary-color);
  font-weight: bold;
}
.product * {
  font-size: 10px;
}
.product .rating {
  font-size: 10px;
}

.profile-image {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
}
.profile-image label {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 5px 0;
}

.banner-image {
  position: relative;
}
.banner-image label {
  position: absolute;
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 5px 10px;
  font-size: 12px;
  right: 10px;
  top: 10px;
  border-radius: 3px;
  border: 1px solid #333;
}

.product-image {
  width: 100%;
  height: 100px;
  object-fit: contain;
  border: 1px solid #ebebeb;
}

@media screen and (min-width: 799px) {
  .business-block .image img {
    height: 180px;
  }
  .event-owl-carousel .owl-item img {
    height: 280px;
  }
  .event-owl-carousel .owl-item.active.center img {
    height: 300px;
  }
}
.textstyle{
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
}

.slider-item {
  h3 {
    // text-align: center;
    // background-color: #555;
    // color: #fff;

    // background: #5f9ea0;
    background: url(images/download.jpeg);
    background-size: cover;
    color: #fff;
    font-size: 36px;
    line-height: 100px;
    margin: 20px;
    padding: 2%;
    position: relative;
    text-align: center;
    border-radius: 5px;

    height: 300px;
  }
}
.slider-item1 {
  h3 {
    // text-align: center;
    // background-color: #555;
    // color: #fff;

    // background: #5f9ea0;
    // background: url(images/download.jpeg);
    background-size: cover;
    color: #fff;
    font-size: 36px;
    line-height: 100px;
    margin: 20px;
    padding: 2%;
    position: relative;
    text-align: center;
    border-radius: 5px;

    height: 300px;
  }
}

.center {
  .slick-center {
    h3 {
      color: #3498db;
      transform: scale(1.08);
    }
  }
}
.back{ 
  background-color: #f4f4f4;
}